.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.footer img {
  width: 60px;
}

.footer a {
  color: #999;
  display: flex;
  align-items: center;
}

.footer-container {
  margin-top: 40px;
}

.version-help {
  display: flex;
  align-items: center;
  color: #999;
}

.version-help a {
  display: inline-block;
  margin-left: 10px;
}
