.main-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 10px;
  right: 10px;
  width: auto!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-menu .ui.menu {
  border-radius: 0 0 .28571429rem .28571429rem;
  border-top: none;
}

.main-menu .ui.menu>.item:last-child {
    border-radius: 0 0 .28571429rem 0;
}

.main-menu .ui.menu>.item:first-child {
    border-radius: 0 0 0 .28571429rem;
}
