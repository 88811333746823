.login-box {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 10%;
  transform: translateX(-50%);
  margin-top: 30px;
}

.login-logo-box {
  text-align: center;
  margin: 10px 0 30px;
}

.login-logo {
  width: 100px;
}
