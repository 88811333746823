.offline-message {
  background: #db2828;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 5;
  transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 10px #db2828;
}

.offline-message i.icon {
  margin-right: 10px;
}
