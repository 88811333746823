.account-container {
  text-align: center;
}

.account-username {
  font-size: 24px;
  margin-top: 30px;
}

.account-email {
  margin-top: 8px;
  margin-bottom: 30px;
  opacity: 0.5;
}
