.main-menu {
  position: fixed;
  z-index: 10;
  top: 0;
  left: 10px;
  right: 10px;
  width: auto!important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.main-menu .ui.menu {
  border-radius: 0 0 .28571429rem .28571429rem;
  border-top: none;
}

.main-menu .ui.menu>.item:last-child {
    border-radius: 0 0 .28571429rem 0;
}

.main-menu .ui.menu>.item:first-child {
    border-radius: 0 0 0 .28571429rem;
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0 20px;
}

.footer img {
  width: 60px;
}

.footer a {
  color: #999;
  display: flex;
  align-items: center;
}

.footer-container {
  margin-top: 40px;
}

.version-help {
  display: flex;
  align-items: center;
  color: #999;
}

.version-help a {
  display: inline-block;
  margin-left: 10px;
}

.login-box {
  width: 300px;
  position: absolute;
  left: 50%;
  top: 10%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  margin-top: 30px;
}

.login-logo-box {
  text-align: center;
  margin: 10px 0 30px;
}

.login-logo {
  width: 100px;
}

.account-container {
  text-align: center;
}

.account-username {
  font-size: 24px;
  margin-top: 30px;
}

.account-email {
  margin-top: 8px;
  margin-bottom: 30px;
  opacity: 0.5;
}

.offline-message {
  background: #db2828;
  color: #fff;
  position: fixed;
  bottom: 0;
  left: 50%;
  z-index: 5;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  padding: 10px;
  border-radius: 5px 5px 0 0;
  box-shadow: 0 0 10px #db2828;
}

.offline-message i.icon {
  margin-right: 10px;
}

body {
  background: #efefef!important;
}

.app-container {
  padding-top: 70px;
}

body,
h1, h2, h3, h4, h5,
.ui.menu,
.ui.button,
.ui.card>.content>.header,
.ui.cards>.card>.content>.header,
.ui.message .header,
.ui.form input:not([type]), .ui.form input[type=date], .ui.form input[type=datetime-local], .ui.form input[type=email], .ui.form input[type=file], .ui.form input[type=number], .ui.form input[type=password], .ui.form input[type=search], .ui.form input[type=tel], .ui.form input[type=text], .ui.form input[type=time], .ui.form input[type=url] {
  font-family: 'Helvetica Neue', 'Roboto', Arial,Helvetica,sans-serif!important;
}

/* COLORS */
.ui.primary.button, .ui.primary.buttons .button { background-color: #e84e0d !important }


@media only screen and (min-width: 768px) {
  .app-container .ui.container {
      width: 768px;
  }
}

.position-point {
  pointer-events: none;
  -webkit-transition: all .3s ease;
  transition: all .3s ease;
  width: 12px;
  height: 12px;
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.position-existing-point {
  position: absolute;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  background: #6435c9;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  line-height: 100%;
  color: #fff;
  font-weight: bold;
  border: none;
  outline: none!important;
  cursor: pointer;
  -webkit-transition: .3s all ease;
  transition: .3s all ease;
  padding: 0;
  z-index: 1;
}

.position-point-additional {
  border-radius: 0!important;
}

.position-point-management {
  border: 2px solid #ff66d9!important;
}


.position-point-wisa {
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    -webkit-transform-origin: 80% -50%;
            transform-origin: 80% -50%;
}

.position-point-wisa span {
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
}

.position-existing-point.active {
  background: red!important;
}

.position-existing-point.offer {
  background: #fbbd08;
}

.position-existing-point.temp {
  z-index: 0;
}

.position-point:after,
.position-point:before {
  content: ' ';
  position: absolute;
  width: 2px;
  height: 12px;
  background: red;
  border-radius: 10px;
  top: 0px;
  left: 5px;
}

.position-point:after {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
}

.position-point:before {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
}


img.prefetch-image {
  position: absolute;
  opacity: 0;
}

/* ROUTER TRANSITION */
.switch-wrapper {
  position: relative;
}

.switch-wrapper > div {
  position: absolute;
  width: 100%;
}

.radio-field {
  margin: 5px;
}

.entry-id {
  opacity: 0.3;
}

.work-widget-image {
  width: 100%;
  height: 200px;
  background-position: 50% 50%;
  background-size: cover;
}

.work-widget-image-no-internet {
    background: #333;
    color: #fff;
    padding: 80px 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 3px 3px 0 0;
}

.work-widget-image-content {
    text-align: center;
}

.work-widget-image-content img {
    max-width: 100%;
    max-height: 55vh;
}

/* FORMS */
.srv-validation-message {
  color: #db2828;
  font-weight: bold;
  font-size: 12px;
}

/* ALERTS */
.ui.message {
  margin: 10px 0 !important;
}

.loading-wrapper {
  position: relative;
  height: 120px;
}

.project-description {
  padding: 10px;
  font-size: 12px;
}

.steps-project-name {
  font-size: 12px;
  opacity: 0.5;
  margin-bottom: 0;
  text-align: center;
}

.steps-plan-name {
  margin-top: 4px;
  text-align: center;
}

.step-headline {
  text-align: center;
}




[class*="zero padding"] {
    padding: 0 !important;
}

[class*="zero top padding"], [class*="zero vertical padding"] {
    padding-top: 0 !important;
}

[class*="zero bottom padding"], [class*="zero vertical padding"] {
    padding-bottom: 0 !important;
}

[class*="zero right padding"], [class*="zero horizontal padding"] {
    padding-right: 0 !important;
}

[class*="zero left padding"], [class*="zero horizontal padding"] {
    padding-left: 0 !important;
}

[class*="zero margin"] {
    margin: 0 !important;
}

[class*="zero top margin"], [class*="zero vertical margin"] {
    margin-top: 0 !important;
}

[class*="zero bottom margin"], [class*="zero vertical margin"] {
    margin-bottom: 0 !important;
}

[class*="zero right margin"], [class*="zero horizontal margin"] {
    margin-right: 0 !important;
}

[class*="zero left margin"], [class*="zero horizontal margin"] {
    margin-left: 0 !important;
}

/* 0.25 rem */
[class*="mini padding"] {
    padding: 0.25rem !important;
}

[class*="mini top padding"], [class*="mini vertical padding"] {
    padding-top: 0.25rem !important;
}

[class*="mini bottom padding"], [class*="mini vertical padding"] {
    padding-bottom: 0.25rem !important;
}

[class*="mini right padding"], [class*="mini horizontal padding"] {
    padding-right: 0.25rem !important;
}

[class*="mini left padding"], [class*="mini horizontal padding"] {
    padding-left: 0.25rem !important;
}

[class*="mini margin"] {
    margin: 0.25rem !important;
}

[class*="mini top margin"], [class*="mini vertical margin"] {
    margin-top: 0.25rem !important;
}

[class*="mini bottom margin"], [class*="mini vertical margin"] {
    margin-bottom: 0.25rem !important;
}

[class*="mini right margin"], [class*="mini horizontal margin"] {
    margin-right: 0.25rem !important;
}

[class*="mini left margin"], [class*="mini margin horizontal"] {
    margin-left: 0.25rem !important;
}

/* 0.5 rem */
[class*="tiny padding"] {
    padding: 0.5rem !important;
}

[class*="tiny top padding"], [class*="tiny vertical padding"] {
    padding-top: 0.5rem !important;
}

[class*="tiny bottom padding"], [class*="tiny vertical padding"] {
    padding-bottom: 0.5rem !important;
}

[class*="tiny right padding"], [class*="tiny horizontal padding"] {
    padding-right: 0.5rem !important;
}

[class*="tiny left padding"], [class*="tiny horizontal padding"] {
    padding-left: 0.5rem !important;
}

[class*="tiny margin"] {
    margin: 0.5rem !important;
}

[class*="tiny top margin"], [class*="tiny vertical margin"] {
    margin-top: 0.5rem !important;
}

[class*="tiny bottom margin"], [class*="tiny vertical margin"] {
    margin-bottom: 0.5rem !important;
}

[class*="tiny right margin"], [class*="tiny horizontal margin"] {
    margin-right: 0.5rem !important;
}

[class*="tiny left margin"], [class*="tiny horizontal margin"] {
    margin-left: 0.5rem !important;
}

/* 1 rem */
[class*="small padding"] {
    padding: 1rem !important;
}

[class*="small top padding"], [class*="small vertical padding"] {
    padding-top: 1rem !important;
}

[class*="small bottom padding"], [class*="small vertical padding"] {
    padding-bottom: 1rem !important;
}

[class*="small right padding"], [class*="small horizontal padding"] {
    padding-right: 1rem !important;
}

[class*="small left padding"], [class*="small horizontal padding"] {
    padding-left: 1rem !important;
}

[class*="small margin"] {
    margin: 1rem !important;
}

[class*="small top margin"], [class*="small vertical margin"] {
    margin-top: 1rem !important;
}

[class*="small bottom margin"], [class*="small vertical margin"] {
    margin-bottom: 1rem !important;
}

[class*="small right margin"], [class*="small horizontal margin"] {
    margin-right: 1rem !important;
}

[class*="small left margin"], [class*="small horizontal margin"] {
    margin-left: 1rem !important;
}

/* 1.5 rem */
[class*="medium padding"] {
    padding: 1.5rem !important;
}

[class*="medium top padding"], [class*="medium vertical padding"] {
    padding-top: 1.5rem !important;
}

[class*="medium bottom padding"], [class*="medium vertical padding"] {
    padding-bottom: 1.5rem !important;
}

[class*="medium right padding"], [class*="medium horizontal padding"] {
    padding-right: 1.5rem !important;
}

[class*="medium left padding"], [class*="medium horizontal padding"] {
    padding-left: 1.5rem !important;
}

[class*="medium margin"] {
    margin: 1.5rem !important;
}

[class*="medium top margin"], [class*="medium vertical margin"] {
    margin-top: 1.5rem !important;
}

[class*="medium bottom margin"], [class*="medium vertical margin"] {
    margin-bottom: 1.5rem !important;
}

[class*="medium right margin"], [class*="medium horizontal margin"] {
    margin-right: 1.5rem !important;
}

[class*="medium left margin"], [class*="medium horizontal margin"] {
    margin-left: 1.5rem !important;
}

/* 2 rem */
[class*="large padding"] {
    padding: 2rem !important;
}

[class*="large top padding"], [class*="large vertical padding"] {
    padding-top: 2rem !important;
}

[class*="large bottom padding"], [class*="large vertical padding"] {
    padding-bottom: 2rem !important;
}

[class*="large right padding"], [class*="large horizontal padding"] {
    padding-right: 2rem !important;
}

[class*="large left padding"], [class*="large horizontal padding"] {
    padding-left: 2rem !important;
}

[class*="large margin"] {
    margin: 2rem !important;
}

[class*="large top margin"], [class*="large vertical margin"] {
    margin-top: 2rem !important;
}

[class*="large bottom margin"], [class*="large vertical margin"] {
    margin-bottom: 2rem !important;
}

[class*="large right margin"], [class*="large horizontal margin"] {
    margin-right: 2rem !important;
}

[class*="large left margin"], [class*="large horizontal margin"] {
    margin-left: 2rem !important;
}

